import { ScrollingModule } from "@angular/cdk/scrolling";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MsalGuard, MsalModule, MsalRedirectComponent } from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  InteractionType,
  PublicClientApplication
} from "@azure/msal-browser";
import { NgIdleModule } from "@ng-idle/core";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ArticleSearchListComponent } from "./components/article-search-list/article-search-list.component";
import { BannerComponent } from "./components/banner/banner.component";
import { ClientDetailExpansionComponent } from "./components/client-detail-expansion/client-detail-expansion.component";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { InputFieldComponent } from "./components/input-field/input-field.component";
import { OrderDetailItemComponent } from "./components/order-detail-item/order-detail-item.component";
import { OrderDetailSummaryComponent } from "./components/order-detail-summary/order-detail-summary.component";
import { OrderDetailComponent } from "./components/order-detail/order-detail.component";
import { OrderItemHistoryComponent } from "./components/order-item-history/order-item-history.component";
import { OrderItemSummaryComponent } from "./components/order-item-summary/order-item-summary.component";
import { OrderNumberHeaderComponent } from "./components/order-number-header/order-number-header.component";
import { OrderSearchListComponent } from "./components/order-search-list/order-search-list.component";
import { TransactionDetailComponent } from "./components/transaction-detail/transaction-detail.component";
import { ArticleSearchPageComponent } from "./pages/article-search-page/article-search-page.component";
import { ClientSearchPageComponent } from "./pages/client-search-page/client-search-page.component";
import { OrderDetailPageComponent } from "./pages/order-detail-page/order-detail-page.component";
import { OrderItemSummaryPageComponent } from "./pages/order-item-summary-page/order-item-summary-page.component";
import { OrderSearchPageComponent } from "./pages/order-search-page/order-search-page.component";
import { TransactionDetailPageComponent } from "./pages/transaction-detail-page/transaction-detail-page.component";
import { ArticleService } from "./services/article.service";
import { AuthInterceptorService } from "./services/auth-interceptor.service";
import { AvailabilityService } from "./services/availability.service";
import { CacheInterceptor } from "./services/cache-interceptor.service";
import { ErrorService } from "./services/error.service";
import { LoadingService } from "./services/loading.service";
import { LocationService } from "./services/location.service";
import { OrderService } from "./services/order.service";
import { ProductService } from "./services/product.service";
import { SalesHistoryService } from "./services/sales-history.service";
import { TokenService } from "./services/token.service";
import { TransactionService } from "./services/transaction.service";
import { ChipDropDownComponent } from "./shared/chip-drop-down/chip-drop-down.component";
import { DateRangePickerComponent } from "./shared/date-range-picker/date-range-picker.component";
import { DropDownComponent } from "./shared/drop-down/drop-down.component";
import { LoadingSpinnerComponent } from "./shared/loading-spinner/loading-spinner.component";
import { PopupModalComponent } from "./shared/popup-modal/popup-modal.component";
import { TimeoutPopupComponent } from "./shared/timeout-popup/timeout-popup.component";

@NgModule({
  declarations: [
    AppComponent,
    ClientSearchPageComponent,
    OrderSearchPageComponent,
    OrderDetailPageComponent,
    OrderItemSummaryPageComponent,
    BannerComponent,
    DropDownComponent,
    OrderSearchListComponent,
    LoadingSpinnerComponent,
    ClientDetailExpansionComponent,
    OrderDetailComponent,
    OrderDetailItemComponent,
    OrderDetailSummaryComponent,
    OrderItemSummaryComponent,
    OrderItemHistoryComponent,
    OrderNumberHeaderComponent,
    InputFieldComponent,
    PopupModalComponent,
    DateRangePickerComponent,
    OrderNumberHeaderComponent,
    ChipDropDownComponent,
    TimeoutPopupComponent,
    TransactionDetailPageComponent,
    TransactionDetailComponent,
    ConfirmationDialogComponent,
    ArticleSearchPageComponent,
    ArticleSearchListComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    ScrollingModule,
    MatTableModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatOptionModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    MatChipsModule,
    MatDialogModule,
    MatExpansionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.azureClientId,
          authority:
            "https://login.microsoftonline.com/78f57c94-422f-440b-a0c8-906018bb649a",
          navigateToLoginRequestUrl: true,
          redirectUri: environment.azureRedirectUri,
          postLogoutRedirectUri: environment.azureRedirectUri,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.SessionStorage,
        },
        system: {
          allowRedirectInIframe: true,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ["user.read"],
        },
      },
      null
    ),
    NgIdleModule.forRoot()
  ],
  providers: [
        // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'en'},
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    LoadingService,
    LocationService,
    ProductService,
    ErrorService,
    OrderService,
    TransactionService,
    SalesHistoryService,
    AvailabilityService,
    TokenService,
    ArticleService,
    CacheInterceptor,
    MsalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: AuthInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang("en");
    const lang = localStorage.getItem('lang');
    if (lang) {
      return translate.use(lang).toPromise();
    }
    return translate.use("en").toPromise();
  };
}
