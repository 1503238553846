import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';
import { Order } from '../models/order.model';
import { ErrorService } from './error.service';
import { LoadingService } from "./loading.service";
import { MsAuthService } from './ms-auth.service';

export interface IOrderResponse {
  data: IOrderData[];
  links: {
    self: string;
    next?: string;
    prev?: string;
  };
  meta: {
    estimatedOrdersTotal: number;
    include: string;
    pageSize?: number;
    correlationId: string;
  };
  included?: IIncluded[];
}

export interface IOrderData {
  type: string;
  id: string;
  attributes: IOrderAttributes;
  relationships?: IRelationships;
  hasReturns?: boolean;
  transactionType?: string;
  transactionRefId?: string;
}

export interface IOrderAttributes {
  orderTypeCode: string;
  orderTypeName: string;
  orderDate: string;
  orderTimestamp: string;
  salesChannelOrderId: string;
  salesChannelId: string;
  locationId: string;
  locationName: string;
  siteSourceId: string;
  siteSourceName: string;
  orderFulfillmentStatusCode: string;
  orderFulfillmentStatusName: string;
  registerId: string;
  associateId: string;
  createdById: string;
  createdByEmail: string;
  customerId: string;
  customerReferenceId: string;
  productCount: number;
  subtotal: number;
  tax: number;
  total: number;
  currency: string;
  deliveryBlock: string;
  shippingConditionCode: string;
  shippingConditionName: string;
  holdUser: string;
  holdTimestamp: string;
  orderProducts: IOrderProduct[];
  addresses?: IOrderAddress[];
  payments: any[];
  relationships?: IRelationships;
  saleAssociateName?: string;
  cashierId?: string;
  cashierName?: string;
}

export interface IPayment {
  type: string;
  id: string;
  attributes: IPaymentData;
}

export interface IPaymentData {
  paymentType: string;
  paymentAmount: number;
  paymentCardHolder: string;
  paymentCardNumber: string;
  paypalAttributes: IPaypalAttributes;
}

export interface IPaypalAttributes {
  paypalEmail: string,
  paypalRequestId: string;
  paypalRequestToken: string;
  paypalAuthTransactionId: string;
  paypalOSRequestId: string;
  paypalOSRequestToken: string;
  paypalOSTransactionId: string;
}

export interface IRelationships {
  returns?: IRelationship;
  shipments?: IRelationship;
}

export interface IRelationship {
  data: IRelationshipData[];
}

export interface IRelationshipData {
  type: string;
  id: string;
}

export interface IOrderAddress {
  addressTypeCode: string;
  addressTypeName: string;
  firstName: string;
  lastName: string;
  line1: string;
  line2: string;
  line3: string;
  cityName: string;
  provinceCode: string;
  postalCode: string;
  countryCode: string;
  phoneNumber: string;
  email: string;
}

export interface IOrderProduct {
  lineItem: string;
  lineItemTypeGroup: string;
  productId: string;
  genericId: string;
  brandName: string;
  productName: string;
  colorName: string;
  sizeName: string;
  upc: string;
  alternativeProductId: string;
  merchandiseCategoryCode: string;
  merchandiseCategoryName: string;
  articleAssignmentCode: string;
  articleAssignmentName: string;
  quantity: number;
  unit: string;
  svcNumber?: number;
  initialPrice: number;
  salesPrice: number;
  discountTotal: number;
  subtotal: number;
  tax: number;
  taxes: ITax[];
  total: number;
  referenceDocumentNumber: string;
  referenceDocumentLine: string;
  lineItemStatusCode: string;
  lineItemStatusName: string;
  returnableQuantity: number;
  returnableQuantities: any[];
  returnReasonCode: string;
  returnReasonName: string;
  returnNotes: string;
  fulfillmentLocationId: string;
  pickupLocationId: string;
  processedQuantity: number;
  processedDate: string;
  backOrderFlag: boolean;
  backOrderDate: string;
  specialServiceCode: string;
  reserveFlag: boolean;
  fulfillmentStatus: IFulfillmentStatus[];
}

export interface ITax {
  taxTypeCode: string;
  taxTypeName: string;
  taxAmount: number;
}

export interface IFulfillmentStatus {
  statusCode: string;
  statusName: string;
  fulfillmentLocationId: string;
  sequence: number;
  rejectionReasonCode?: string;
  userId: string;
  timestamp: string;
}

export interface IIncluded {
  type: string;
  id: string;
  attributes: IIncludeShipmentAttributes | IOrderAttributes;
}

export interface IIncludeShipmentAttributes {
  shipmentOrderId: string;
  shipDate: string;
  shippingPointCode: string;
  shippingPointName: string;
  carrierCode: string;
  carrierName: string;
  serviceLevelCode: string;
  shipmentProducts: IShipmentProduct[];
}

export interface IShipmentProduct {
  lineItem: string;
  orderLineItem: string;
  quantity: number;
  unit: string;
  trackingNumbers: ITrackingNumber[];
}

export interface ITrackingNumber {
  trackingItemLineItem: string;
  trackingItemTypeCode: string;
  trackingItemTypeName: string;
  trackingIdentifierId: string;
  trackingNumber: string;
}

export interface IOrder {
  firstName: string; //cdc
  lastName: string; //cdc
  email: string; //cdc
  phone: string; //cdc
  status: string;
  orderId: string;
  orderDate: string;
  totalCount: number;
  clientId?: string;
  billingAddress: IOrderAddress;
  shippingAddress: IOrderAddress;
  originalPrice: string;
  discount: string;
  subtotal: string;
  tax: string;
  shippingFee: string;
  total: string;
  items: IItem[];
}

export interface IItem {
  name: string;
  id: string;
  color: string;
  size: string;
  quantity: number;
  priceList: number;
  price: number;
  trackingNumber: string;
  fulfillingStore: string;
  status: string;
  upc: string;
  history: IHistory[];
}

export interface IHistory {
  date: string;
  location: string;
  user: string;
  status: string;
  reason?: string;
}

export interface IProductIdBanner {
  productId: string;
  banner: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private _host = environment.apigee.host;

  public orders: Order[] = [];
  
  // skipcq
  constructor(
    private _httpClient: HttpClient,
    private _errorService: ErrorService,
    private _msAuthService: MsAuthService,
    private _loadingService: LoadingService
  ) {}

  public getOrderById(id: string): Observable<Order[] | null> {
    let url = `${this._host}/orders/${id}?include=returns,shipments&fulfillmentstatushistory=true`;
    return this.getOrders(url);
  }

  private getOrders(url: string): Observable<Order[]> {
    return this._httpClient.get<IOrderResponse>(url).pipe(
      map((result: IOrderResponse): Order[] => {
        if (result.data.length === 0) {
          throw new Error('No orders found');
        }
        const orders = result.data.map(
          (order) =>
            new Order(order, result.meta.estimatedOrdersTotal, result.included)
        );
        this.orders =
          this.orders.length > 0 ? this.orders.concat(orders) : orders;
        return orders;
      }),
      catchError((e) => {
        console.log(e);
        if (e.message === 'No orders found') {
          this._errorService.showError('errors.ordersNoOrders');
        } else {
          this._errorService.showGeneralError(true);
        }
        this._loadingService.stopLoading();
        throw e;
      })
    );
  }

  public reassignStore$(orderId: string, lineItem: string, locationId: string): Observable<any> { 
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${this._host}/orders`;
    const uuid = uuidv4()
    const body = {
      data: [
        {
          type: "orders",
          id: uuid,
          attributes: {
            salesChannelOrderId: orderId,
            holdUser: this._msAuthService.getName(),
            orderProducts: [
              {
                lineItem: lineItem,
                fulfillmentStatus: [
                  {
                      locationId: locationId
                  }
                ]
              }
            ]
          }
        }
      ]
    }
    return this._httpClient.patch(url, JSON.stringify(body), {headers: headers}).pipe(
      catchError((e) => {
        console.log(e);
        this._errorService.showGeneralError(true);
        throw e;
      })
    );
  }

  public resetOrders(): void {
    this.orders = [];
  }
}
