<div class="container" *ngIf="item || transactionItem">
    <div class="image-container">
      <mat-spinner *ngIf="!noImage && imgUrl === null" [diameter]="50"></mat-spinner>
      <img *ngIf="!noImage && imgUrl !== null" class="image" [src]="imgUrl" [alt]="name" />
    </div>
    <div class="info-container">
      <div class="text-container">
        <div class="brand">{{ brand }}</div>
        <div class="item-name">{{ name }}</div>
        <div *ngIf="productId && productId !==''" class="text-wrapper text-spacing">
            <span class="text-label">{{ 'orderDetailItem.itemNumber' | translate }}:&nbsp;</span>
            <span class="text">{{ productId }}</span>
        </div>
        <div *ngIf="transactionItem" class="text-wrapper">
          <span class="text-label">{{'orderDetailItem.transactionType' | translate}}:&nbsp;</span>
          <span class="text"
            [ngClass]="{'red-text':
            (transactionItem.statusId === '2801' ||
            transactionItem.statusId === '2802' ||
            transactionItem.statusId === '2803' ||
            transactionItem.statusId === '2804')}">
            {{ transactionItem.status | translate }}
          </span>
        </div>
        <div *ngIf="serialId && serialId !==''" class="text-wrapper">
            <span class="text-label">{{'orderDetailItem.giftCardId' | translate}}:&nbsp;</span>
            <span class="text">{{ serialId }}</span>
        </div>
        <div *ngIf="ecommId && ecommId !==''" class="text-wrapper">
          <span class="text-label">{{'orderDetailItem.ecommId' | translate}}:&nbsp;</span>
          <span class="text">{{ ecommId }}</span>
        </div>
        <div *ngIf="layawayId && layawayId !==''" class="text-wrapper">
          <span class="text-label">{{'orderDetailItem.layawayId' | translate}}:&nbsp;</span>
          <span class="text">{{ layawayId }}</span>
        </div>
        <div *ngIf="specialOrderId && specialOrderId !==''" class="text-wrapper">
          <span class="text-label">{{'orderDetailItem.specialOrderId' | translate}}:&nbsp;</span>
          <span class="text">{{ specialOrderId }}</span>
        </div>
        <div *ngIf="salesDeliveryId && salesDeliveryId !==''" class="text-wrapper">
          <span class="text-label">{{'orderDetailItem.salesDeliveryId' | translate}}:&nbsp;</span>
          <span class="text">{{ salesDeliveryId }}</span>
        </div>
        <div *ngIf="collection && collection !==''" class="text-wrapper">
          <span class="text-label">{{ 'orderDetailItem.length' | translate }}:&nbsp;</span>
          <span class="text">{{ collection }}</span>
      </div>
        <div *ngIf="color && color !==''" class="text-wrapper">
            <span class="text-label">{{ 'orderDetailItem.color' | translate }}:&nbsp;</span>
            <span class="text">{{ color }}</span>
        </div>
        <div *ngIf="size && size !==''" class="text-wrapper">
            <span class="text-label">{{ 'orderDetailItem.size' | translate }}:&nbsp;</span>
            <span class="text">{{ size }}</span>
        </div>
        <div *ngIf="quantity"class="text-wrapper">
            <span class="text-label">{{ 'orderDetailItem.quantity' | translate }}:&nbsp;</span>
            <span class="text">{{ quantity }}</span>
        </div>
        <div *ngIf="price" class="text-wrapper">
            <span class="text-label">{{ 'orderDetailItem.price' | translate }}:&nbsp;</span>
            <span
              class="text"
              [ngClass]="{
              'on-sale': price < originalPrice,
              text: price >= originalPrice}">
              {{ price | currency }}&nbsp;{{currency}}
            </span>
            <span class="original-price" *ngIf="price < originalPrice">
              {{ originalPrice | currency }}&nbsp;{{currency}}
            </span>
        </div>
        <div *ngIf="currentOnlinePrice" class="text-wrapper">
            <span class="text-label">{{ 'orderDetailItem.currentOnlinePrice' | translate }}:&nbsp;</span>
            <span class="text">{{ currentOnlinePrice | currency }}&nbsp;{{currency}}</span>
        </div>
        <div *ngIf="order && item.backOrderDate !== ''" class="text-wrapper text-spacing">
            <div class="special-order-text">
                <span>{{ 'orderDetailItem.specialOrderDate' | translate }}:&nbsp;</span>
                <span>({{ 'orderDetailItem.dateFormat' | translate }})</span>
            </div>
            <span class="text">{{ item.backOrderDate }}</span>
        </div>
        <div *ngIf="order && order.type !== 'RETAILTRANSACTION' && order.isDeliveryOrder" class="text-wrapper text-spacing">
            <span class="text-label">{{ 'orderDetailItem.trackingNumber' | translate }}:&nbsp;</span>
            <span class="text">{{ item.trackingNumbers.join(', ') }}</span>
        </div>
        <div *ngIf="order && (order.type !== 'RETAILTRANSACTION' || !original)" class="text-wrapper">
          <span *ngIf="!isManager || item.history[0]?.statusCode !== '8' || storeReassigned">{{ 'orderDetailItem.location' | translate }}:&nbsp;</span>
          <span *ngIf="!isManager || item.history[0]?.statusCode !== '8' || storeReassigned" class="text">{{ item.fulfillingStore }}</span>
          <drop-down
            class="drop-down"
            *ngIf="isManager && item.history[0]?.statusCode === '8' && !storeReassigned"
            [label]="'orderDetailItem.location' | translate"
            [data]="storeData"
            [enableFilter]="true"
            [multiple]="false"
            [formControl]="storeControl"
            [filterText]="'orderDetailItem.search' | translate"
            ngDefaultControl
          ></drop-down>
        </div>
        <div *ngIf="order && (order.type !== 'RETAILTRANSACTION' || !original)" class="text-wrapper status-wrapper">
          <span class="text-label">{{ 'orderDetailItem.status' | translate }}:&nbsp;</span>
          <span class="status-text" (click)="statusClicked()">{{
            item.status | translate
          }}</span>
        </div>
      </div>
      <div *ngIf="transactionItem &&
        transactionItem.shippingName &&
        transactionItem.shippingName !== '' &&
        transactionItem.shippingStreetAddress &&
        transactionItem.shippingStreetAddress !== ''"
        class="text-container">
        <span class="item-name">{{ 'orderDetailItem.shipToAddress' | translate }}:&nbsp;</span>
        <span class="address-text" data-dd-action-name="Client Name" data-dd-privacy="mask">{{ transactionItem.shippingName }}</span>
        <span class="address-text" data-dd-action-name="Client Phone" data-dd-privacy="mask">{{ transactionItem.shippingPhone }}</span>
        <span class="address-text" data-dd-action-name="Client Address" data-dd-privacy="mask">{{ transactionItem.shippingStreetAddress }}</span>
        <span class="address-text" data-dd-action-name="Client Address" data-dd-privacy="mask">{{
          transactionItem.cityName +
          ', ' +
          transactionItem.provinceCode +
          ' ' +
          transactionItem.postalCode
        }}</span>
        <span class="address-text" data-dd-action-name="Client Address" data-dd-privacy="mask">{{
          transactionItem.countryCode
        }}</span>
      </div>
    </div>
</div>
