import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Client } from '../models/client.model';
import { SalesHistory } from '../models/sales-history.model';
import { CDCService, IClientSearchParams } from '../services/cdc.service';
import { ErrorService } from '../services/error.service';
import { LoadingService } from '../services/loading.service';
import { SalesHistoryService, TransactionSelections } from '../services/sales-history.service';

@Injectable({
  providedIn: 'root',
})
export class OrderSearchResolver implements Resolve<SalesHistory[]> {
  constructor(
    private _cdcService: CDCService,
    private _router: Router,
    private _errorService: ErrorService,
    private _loadingService: LoadingService,
    private _translateService: TranslateService,
    private _salesHistoryService: SalesHistoryService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<SalesHistory[]> {
    if(route.queryParams.email || route.queryParams.phone) {
      if(route.queryParams.start && route.queryParams.end){
        this._salesHistoryService.dateRange = new FormGroup({
          start: new FormControl(new Date(route.queryParams.start)),
          end: new FormControl(new Date(route.queryParams.end))
        });
      }else{
        this._salesHistoryService.dateRange = new FormGroup({
          start: new FormControl(new Date(new Date().setDate(new Date().getDate() - 30))),
          end: new FormControl(new Date())
        });
      }
      this._salesHistoryService.transactionType = TransactionSelections.all;
      const searchQuery: IClientSearchParams = { fields: ['data', 'profile'] };
      if(route.queryParams.email) {
        searchQuery.email = route.queryParams.email;
      }
      if(route.queryParams.phone) {
        searchQuery.phoneNumber = route.queryParams.phone;
      }
      return this._cdcService
        .clientSearch(searchQuery).pipe(switchMap((clients: Client[]) => {
          this._salesHistoryService.customerIds = clients.reduce((arr, res) => {
            arr.push(...res.ids);
            return arr;
          }, <string[]>[]);
          this._loadingService.startLoading();
          return this._salesHistoryService
            .getInitialSalesHistory(this._salesHistoryService.customerIds)
            .pipe(
              switchMap((sales) => {
                this._loadingService.stopLoading();
                if(sales === null) {
                  this._errorService.showError('errors.tooMuchIds');
                  throw new Error(
                    this._translateService.instant('errors.tooMuchIds')
                  );
                } else if (sales.length > 0) {
                  return of(sales);
                } else {
                  this._errorService.showError('errors.ordersNoOrders');
                  throw new Error(
                    this._translateService.instant('errors.ordersNoOrders')
                  );
                }
              }),
              catchError(() => {
                this._loadingService.stopLoading();
                return of(null);
              })
            );
        }))
    }
    if (this._salesHistoryService.sales.length > 0) {
      return of(this._salesHistoryService.sales);
    } else if (this._salesHistoryService.customerIds.length > 0) {
      this._loadingService.startLoading();
      return this._salesHistoryService
        .getInitialSalesHistory(this._salesHistoryService.customerIds)
        .pipe(
          switchMap((sales) => {
            this._loadingService.stopLoading();
            if(sales === null) {
              this._errorService.showError('errors.tooMuchIds');
              throw new Error(
                this._translateService.instant('errors.tooMuchIds')
              );
            } else if (sales.length > 0) {
              return of(sales);
            } else {
              this._errorService.showError('errors.ordersNoOrders');
              throw new Error(
                this._translateService.instant('errors.ordersNoOrders')
              );
            }
          }),
          catchError(() => {
            this._loadingService.stopLoading();
            return of(null);
          })
        );
    } else {
      this._errorService.showError('errors.noCustomerId');
      this._router.navigateByUrl('/client-search');
      throw new Error(this._translateService.instant('errors.noCustomerId'));
    }
  }
}
