import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, fromEvent } from 'rxjs';
import { Client } from '../../models/client.model';
import { CDCService } from '../../services/cdc.service';
import { MsAuthService } from '../../services/ms-auth.service';
import { SalesHistoryService } from '../../services/sales-history.service';
import { IDictionary } from '../../shared/models/dictionary.model';

@Component({
  selector: 'order-search-list',
  templateUrl: './order-search-list.component.html',
  styleUrls: ['./order-search-list.component.scss'],
})
export class OrderSearchListComponent implements OnInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;

  public currentArray: IDictionary[] = [];
  private _dataStream: BehaviorSubject<IDictionary[]> = new BehaviorSubject<
    IDictionary[]
  >([]);
  public dataStream$: Observable<IDictionary[]> =
    this._dataStream.asObservable();
  public theEnd = false;
  public loading = false;
  private _firstLoad = true;
  private _subscriptions = new Subscription();

  constructor(
    private _router: Router,
    private _msAuthService: MsAuthService,
    public cdcService: CDCService,
    private _salesHistoryService: SalesHistoryService
  ) {}

  ngOnInit(): void {
    if (this._salesHistoryService.sales.length > 0) {
      if (this.cdcService.clients.length === 0) {
        this._subscriptions.add(
          this.cdcService.clients$.subscribe(() => {
            this._salesHistoryService.setClientSale();
          })
        );
      } else {
        this._salesHistoryService.setClientSale();
      }
      this._subscriptions.add(
      this._salesHistoryService.clientSales$.subscribe((result : IDictionary[]) => {
        if(result.length < 1){
          this._router.navigateByUrl(`/client-search`);
        } else {
          this.currentArray = result;
          this._dataStream.next(this.currentArray);
        }
      }))
    } else {
      this._router.navigateByUrl(`/client-search`);
    }
  }

  ngAfterViewInit(): void {
    if(this.viewport) {
      this._subscriptions.add(fromEvent(this.viewport.elementRef.nativeElement,'scroll')
      .subscribe((e: Event) => {
        this._salesHistoryService.salesListYAxis = e.target['scrollTop']
      }));
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public nextBatch(): void {
    if (
      !this.loading &&
      !this.theEnd &&
      this.viewport &&
      this.viewport.getRenderedRange().end === this.viewport.getDataLength()
    )
      this.getBatch();
    if(this._firstLoad && this.viewport) {
      this._firstLoad = false;
      this.viewport.scrollTo({top: this._salesHistoryService.salesListYAxis});
    }
  }

  public saleClicked(id: string, type: string, store?: string, registerId?: string, date?: string): void {
    if(type === 'transactions') {
      this._router.navigateByUrl(`/transaction/${id}/${registerId}/${store}/${date.split('-').join('')}`, {
        skipLocationChange: false,
      });
    } else {
      this._router.navigateByUrl(`/order/${id}`, {
        skipLocationChange: false,
      });
    }
  }

  public getName(client: Client): string {
    return client
      ? `${client.firstName} ${client.lastName}`
      : `UNKNOWN`;
  }

  public getPhone(client: Client): string {
    return client ? client.phone : `UNKNOWN`;
  }

  public getEmail(client: Client): string {
    return client ? client.email : `UNKNOWN`;
  }

  private getBatch(): void {
    if (this.currentArray.length > 0) this.loading = true;
    if (this.currentArray.length >= this.currentArray[0].totalCount)
      this.theEnd = true;
    this._subscriptions.add(
      this._salesHistoryService.getNextSalesHistory().subscribe((sales) => {
        this._salesHistoryService.setClientSale();
        this.loading = false;
      })
    );
  }
}
