import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { concatMap, skip } from "rxjs/operators";
import { Order } from '../../models/order.model';
import { OrderResolver } from '../../resolvers/order.resolver';
import { CacheInterceptor } from '../../services/cache-interceptor.service';
import { MsAuthService } from '../../services/ms-auth.service';
import { SalesHistoryService } from '../../services/sales-history.service';


@Component({
  selector: 'order-item-summary-page',
  templateUrl: './order-item-summary-page.component.html',
  styleUrls: ['./order-item-summary-page.component.scss'],
})
export class OrderItemSummaryPageComponent implements OnInit, OnDestroy {
  public order: Order | undefined;
  public itemIndex = 0;
  private _subscriptions = new Subscription();

  constructor(
      private _route: ActivatedRoute,
      private _router: Router,
      private _msAuthService: MsAuthService,
      private _cacheInterceptor: CacheInterceptor,
      private _orderResolver: OrderResolver,
      private _salesHistoryService: SalesHistoryService
  ) {}

  ngOnInit(): void {
    this._subscriptions.add(
        this._msAuthService.loggedIn$
            .pipe(
                skip(1),
                concatMap((..._) => {
                    this._cacheInterceptor.clearCache();
                    this._salesHistoryService.resetSalesHistory();
                    return this._orderResolver.resolve(this._route.snapshot);
                })
            )
            .subscribe({
                next: (_) => this._fetchResolvedOrder(),
            })
    );
    this._fetchResolvedOrder();
  }

  private _fetchResolvedOrder() {
  if (
      this._route.snapshot.data.order &&
      this._route.snapshot.data.order.length > 0
    ) {
      this.order = this._route.snapshot.data.order[0];
      this._subscriptions.add(
        this._route.params.subscribe((params) => {
          this.itemIndex = params['itemIndex'];
        })
      );
    } else {
      this._router.navigateByUrl('/client-search');
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
