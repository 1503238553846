import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { ArticleService } from "./services/article.service";
import { ErrorService } from "./services/error.service";
import { LocationService } from "./services/location.service";
import { MsAuthService } from "./services/ms-auth.service";
import { SalesHistoryService } from "./services/sales-history.service";
import { ScannerService } from "./services/scanner.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public showSearchButton = false;
  public showGeneralError = false;
  public frenchLang = false;
  public isIframe = false;
  public orderSub = new Subscription();

  constructor(
    private _router: Router,
    private _salesHistoryService: SalesHistoryService,
    private _locationService: LocationService,
    private _snackBar: MatSnackBar,
    private _errorService: ErrorService,
    private _scannerService: ScannerService,
    private _msAuthService: MsAuthService,
    private _articleService: ArticleService,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
  ) {
    const lang = localStorage.getItem('lang');
    if (lang) {
      this._locale = lang;
      this._adapter.setLocale(this._locale);
    }

    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.showSearchButton = !this._router.url.includes("/client-search");
      });

    this._errorService.showError$.subscribe((error) => {
      this._snackBar.open(error, "OK", { panelClass: "error-message", duration: 5000 });
    });

    this._errorService.showGeneralError$.subscribe((val) => {
      this.showGeneralError = val;
    });
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this._msAuthService.silentToken$.subscribe((value) => {
      if(value) {
        this._locationService.getStores().subscribe();
      }
    });
  }

  public searchButtonClicked(): void {
    this._router.navigateByUrl(`/client-search`);
  }

  public backButtonClicked(): void {
    if (
      this._router.url.includes("/order") &&
      !this._router.url.includes("/item") &&
      this._salesHistoryService.sales.length === 1
    ) {
      this._router.navigateByUrl(`/client-search`);
    } else if(this._router.url.includes("transaction") && !this._router.url.includes("/order") && this._articleService.articleId !== '' && this._articleService.articleTransactions.length > 1) {
      const trxDate = this._articleService.articleTransactions[0].transactionDate.replace(/-/g,"");
      this._router.navigateByUrl(`/article/${this._articleService.articleId}/${this._articleService.articleTransactions[0].locationId}/${trxDate}`);
    } else if (this._router.url.includes("/order-search")) {
      this._router.navigateByUrl(`/client-search`);
    } else if(this._router.url.includes("/order") && !this._router.url.includes("/item") && !this._router.url.includes("/transaction")){
      this._router.navigateByUrl(`/order-search`);
    } else if (this._router.url.includes("/order") && this._router.url.includes("/item")){
      let redirectUrl = this._router.url.split('/order/')[1].split('/')[0];
      this._router.navigateByUrl(`/order/${redirectUrl}`);
    } else {
      this._router.navigateByUrl(`/client-search`);
    }
  }

  @HostListener("window:keydown", ["$event"])
  public scannerInputCheck(e: KeyboardEvent) {
    let scanRegexCharacter = new RegExp(/([A-Za-z0-9])/gm); // alphanumeric
    if (e.key.length === 1 && scanRegexCharacter.test(e.key)) {
      this._scannerService.input(e.key);
    }
  }
}